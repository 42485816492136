document.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll('.modal-trigger').forEach(trigger => {
    trigger.addEventListener('click', () => {
      const { modalTrigger } = trigger.dataset
      const popupModal = document.querySelector(`[data-modal="${modalTrigger}"]`)
      popupModal.classList.add('is-active')
      
      popupModal.querySelectorAll('.modal-close, .modal-background, .modal-close-alt').forEach(closeElm => {
        closeElm.addEventListener('click', () => {
         popupModal.classList.remove('is-active')
        })
      })
    })
  })
});
